import React from 'react';
import Box from '../../components/fela/Box';
import { FelaCSS } from '../../components/fela/flowtypes';
import colorParse from '../../fela-utils/color-parse';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';

interface IProps extends IStylingProps {
  icon: Function;
  color?: TColor | string;
  color2?: TColor;
  width: number | '100%';
  viewBoxWidth: number;
  viewBoxHeight: number;
  style?: FelaCSS;
}

const IconWrap = ({
  icon,
  color = 'mineShaft',
  color2 = 'mineShaft',
  width,
  viewBoxWidth,
  viewBoxHeight,
  style: passThroughStyle = {},
  ...restProps
}: IProps) => {
  const Icon = icon;
  const iconColor = colorParse(color);
  const iconColor2 = colorParse(color2);
  const height = width === '100%' ? '100%' : Math.round(width * (viewBoxHeight / viewBoxWidth));

  return (
    <Box
      style={{
        flexShrink: 0,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...passThroughStyle,
      }}
      {...restProps}>
      <Icon {...{ iconColor, iconColor2, width, height }} />
    </Box>
  );
};

export default IconWrap;
