import { IStyle } from 'fela';
import type { Merge } from 'ramda/tools';
import React, {
  ForwardedRef,
  FunctionComponent,
  HTMLProps,
  ReactNode,
  useContext,
  useRef,
} from 'react';
import { RendererContext } from 'react-fela';
import getSizeValue from '../../fela-utils/get-size-value';
import getSpacingValue from '../../fela-utils/get-spacing-value';
import mergeStyles from '../../fela-utils/merge-styles';
import responsiveStylePropQueries from '../../fela-utils/responsive-style-prop-queries';
import { IStylingProps } from '../../themes/types';
import { TSetRef } from '../../types';
import AutoFocus from './AutoFocus';
import { FelaCSS } from './flowtypes';

export interface IProps extends IStylingProps, Omit<HTMLProps<ReactNode>, 'style' | 'color'> {
  as?: string;
  dataTest?: string;
  autoFocus?: boolean;
  preventScroll?: boolean;
  style?: FelaCSS | Merge<FelaCSS, FelaCSS, 'deep'>;
  setRef?: TSetRef | ReturnType<typeof useRef> | ForwardedRef<HTMLElement>;
  viewBox?: string;
  x?: number;
  y?: number;
}

const Box: FunctionComponent<IProps> = ({
  as,
  style,
  autoFocus,
  preventScroll,
  setRef,
  p,
  px,
  py,
  pl,
  pr,
  pt,
  pb,
  m,
  mx,
  my,
  ml,
  mr,
  mt,
  mb,
  w,
  gap,
  display = 'flex',
  position = 'relative',
  flexDirection = 'column',
  alignItems,
  justifyContent,
  maxW,
  minW,
  h,
  maxH,
  minH,
  dataTest,
  ...restProps
}) => {
  const renderer = useContext(RendererContext);

  const boxStyle: FelaCSS = mergeStyles([
    getSpacingValue('padding', p),
    getSpacingValue('paddingHorizontal', px),
    getSpacingValue('paddingVertical', py),
    getSpacingValue('paddingLeft', pl),
    getSpacingValue('paddingRight', pr),
    getSpacingValue('paddingTop', pt),
    getSpacingValue('paddingBottom', pb),
    getSpacingValue('margin', m),
    getSpacingValue('marginHorizontal', mx),
    getSpacingValue('marginVertical', my),
    getSpacingValue('marginLeft', ml),
    getSpacingValue('marginRight', mr),
    getSpacingValue('marginTop', mt),
    getSpacingValue('marginBottom', mb),
    getSpacingValue('gap', gap),
    getSizeValue('width', w),
    getSizeValue('maxWidth', maxW),
    getSizeValue('minWidth', minW),
    getSizeValue('height', h),
    getSizeValue('maxHeight', maxH),
    getSizeValue('minHeight', minH),
    responsiveStylePropQueries('display', display),
    responsiveStylePropQueries('flexDirection', flexDirection),
    responsiveStylePropQueries('alignItems', alignItems),
    responsiveStylePropQueries('justifyContent', justifyContent),
    responsiveStylePropQueries('position', position),
    { ...style },
  ]);

  const className = renderer.renderRule(() => boxStyle as IStyle, {});
  const elementProps = {
    ...restProps,
    className: `${className} ${restProps.className || ''}`,
    ref: setRef,
  };

  if (dataTest) elementProps['data-test'] = dataTest;

  const element = React.createElement(as || 'div', elementProps);

  if (autoFocus)
    return (
      <AutoFocus preventScroll={preventScroll} autoFocus={autoFocus}>
        {element}
      </AutoFocus>
    );
  return element;
};

export default Box;
