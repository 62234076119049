import { FelaCSS } from '@bridebook/ui';
import { TFelaCSSSizeProps } from '../components/fela/flowtypes';
import { TSizeValue, TSizeValues } from '../themes/types';
import responsiveStylePropQueries from './responsive-style-prop-queries';

const getWidthHeight = (size: TSizeValue) => {
  if (typeof size === 'string') {
    return size;
  }

  if (typeof size === 'number' && size % 1 === 0) {
    return `${size}px`;
  }

  if (typeof size === 'number' && size < 1) {
    return `${size * 100}%`;
  }

  return size;
};

const parseSizingValue = (size: TSizeValues) => {
  const isValueArray = Array.isArray(size);

  if (!isValueArray) {
    return getWidthHeight(size as TSizeValue);
  }

  return size.map((value) => getWidthHeight(value));
};

const getSizeValue = (sizePropName: TFelaCSSSizeProps, size: TSizeValues): FelaCSS =>
  size ? responsiveStylePropQueries(sizePropName, parseSizingValue(size)) : {};

export default getSizeValue;
