import { keys, omit, pick } from 'ramda';
import { IStylingProps } from 'src/themes/types';

/**
 * Object with values "true" used for type safety, to make sure we have listed all the keys
 */
const _stylingProps: Record<keyof IStylingProps, boolean> = {
  h: true,
  m: true,
  maxH: true,
  maxW: true,
  mb: true,
  minH: true,
  minW: true,
  ml: true,
  mr: true,
  mt: true,
  mx: true,
  my: true,
  p: true,
  pb: true,
  pl: true,
  pr: true,
  pt: true,
  px: true,
  py: true,
  w: true,
};

/**
 * Removes keys related to styling from the passed props object
 * @param props
 */
export const stripStylingProps = <T extends IStylingProps>(
  props: T,
): Omit<T, keyof IStylingProps> => omit(keys(_stylingProps), props);

/**
 * Returns only the styling props from a bigger component's props object
 * eg. to pass them to Box
 */
export const extractStylingProps = <T extends IStylingProps>(
  props: T,
): Pick<T, keyof IStylingProps> => pick(keys(_stylingProps), props);
