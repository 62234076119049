import { TFelaCSSSpacingProps } from '../components/fela/flowtypes';
import { ISpacing, TSpacingStep, TSpacingValue } from '../themes/types';
import spacing from '../themes/variables/spacing';
import responsiveStylePropQueries from './responsive-style-prop-queries';

// checks if a themed value (number) is passed, otherwise defaults to the passed value that could be hardcoded in px, em, etc..
export const getThemedSpacing = (value: TSpacingValue) => spacing[value as keyof ISpacing] ?? value;

// sets either singular value to all screen sizes or parses an array and adds media queries accordingly
const getThemedValues = (spacingValue: TSpacingStep) => {
  const isValueArray = Array.isArray(spacingValue);

  if (!isValueArray) {
    return getThemedSpacing(spacingValue as TSpacingValue);
  }

  return spacingValue.map((value) => getThemedSpacing(value));
};

// accepts a css property for margin or padding and applies either themed or hardcoded values
export const getSpacingValue = (
  spacingPropName: TFelaCSSSpacingProps,
  spacingPropValue: TSpacingStep,
) =>
  spacingPropValue
    ? responsiveStylePropQueries(spacingPropName, getThemedValues(spacingPropValue))
    : {};

export default getSpacingValue;
