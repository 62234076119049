import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  center: FelaCSS;
}

const styles = (): IStyles => ({
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 100,
    transform: 'translateX(-50%) translateY(-50%)',
  },
});

export default styles;
