import { alpha } from '../../../fela-utils/color-mixer';
import { colors } from '../../../themes/variables';
import { TButtonV2Size } from '../../../types';
import { FelaCSS } from '../../fela/flowtypes';
import { TButtonVariant } from './button';

interface IStyles {
  wrapper: FelaCSS;
  button: FelaCSS;
  loader: FelaCSS;
}

interface IProps {
  variant: TButtonVariant;
  size: TButtonV2Size;
  hasChildren: boolean;
  alternative: boolean;
  isDisabled: boolean;
  isLoading: boolean;
}

const styles = ({
  variant,
  size,
  hasChildren,
  alternative,
  isDisabled,
  isLoading,
}: IProps): IStyles => {
  const getVariantColors = (): FelaCSS => {
    if (variant === 'primary')
      return {
        backgroundColor: colors.indigoCrush,
        color: colors.white,
        ...(isDisabled
          ? {
              backgroundColor: alpha(colors.space, 40),
              color: colors.space04,
              cursor: 'not-allowed',
            }
          : {
              hover: {
                backgroundColor: colors.indigoCrush80,
                cursor: 'pointer',
              },
              active: {
                backgroundColor: colors.indigoCrush120,
              },
            }),
        ...(alternative && {
          backgroundColor: colors.white,
          color: colors.space,
          hover: {
            backgroundColor: colors.space15,
          },
          active: {
            backgroundColor: colors.space40,
          },
        }),
      };

    if (variant === 'secondary')
      return {
        boxShadow: `inset 0px 0px 0px 1px ${alpha(colors.space, 15)}`,
        color: colors.space,
        backgroundColor: colors.white,
        ...(isDisabled
          ? {
              color: alpha(colors.space, 30),
              cursor: 'not-allowed',
            }
          : {
              hover: {
                backgroundColor: alpha(colors.space, 4),
                cursor: 'pointer',
              },
              active: {
                backgroundColor: alpha(colors.space, 8),
              },
            }),
        ...(alternative && {
          boxShadow: `inset 0px 0px 0px 1px ${colors.white}`,
          backgroundColor: 'transparent',
          color: colors.white,
          hover: {
            backgroundColor: alpha(colors.white, 15),
          },
          active: {
            backgroundColor: alpha(colors.white, 40),
          },
        }),
      };

    if (variant === 'secondary-blue')
      return {
        boxShadow: `inset 0px 0px 0px 1px ${colors.indigoCrush}`,
        color: colors.indigoCrush,
        backgroundColor: colors.white,
        ...(isDisabled
          ? {
              boxShadow: `inset 0px 0px 0px 1px ${colors.indigoCrush40}`,
              color: alpha(colors.indigoCrush, 40),
              cursor: 'not-allowed',
            }
          : {
              hover: {
                backgroundColor: colors.indigoCrush10,
                cursor: 'pointer',
              },
              active: {
                backgroundColor: colors.indigoCrush20,
              },
            }),
        ...(alternative && {
          boxShadow: `inset 0px 0px 0px 1px ${colors.white}`,
          backgroundColor: 'transparent',
          color: colors.white,
          hover: {
            backgroundColor: alpha(colors.white, 15),
          },
          active: {
            backgroundColor: alpha(colors.white, 40),
          },
        }),
      };

    if (variant === 'ghost')
      return {
        backgroundColor: 'transparent',
        color: colors.space,
        ...(isDisabled
          ? {
              cursor: 'not-allowed',
              color: alpha(colors.space, 40),
            }
          : {
              hover: {
                backgroundColor: alpha(colors.space, 4),
              },
              active: {
                backgroundColor: alpha(colors.space, 8),
              },
            }),
        ...(alternative && {
          color: colors.white,
          hover: {
            backgroundColor: alpha(colors.white, 15),
          },
          active: {
            backgroundColor: alpha(colors.white, 40),
          },
        }),
      };

    if (variant === 'link')
      return {
        border: 'none',
        color: colors.indigoCrush,
        backgroundColor: 'transparent',
        ...(isDisabled
          ? {
              cursor: 'not-allowed',
              color: alpha(colors.indigoCrush, 40),
            }
          : {
              hover: {
                textDecorationLine: 'underline',
              },
              active: {
                cursor: 'pointer',
                textDecorationLine: 'underline',
              },
            }),
        ...(alternative && {
          color: colors.white,
        }),
        padding: '0',
        width: 'auto',
      };
  };

  const variantStyle = getVariantColors();

  return {
    wrapper: {
      justifyContent: 'center',
      borderRadius: 100,

      ...(size === 'large' && {
        paddingHorizontal: 8,
        height: 56,
        fontDefault: 16,
        //Icon only
        ...(!hasChildren && {
          padding: 5,
          width: 56,
        }),
      }),

      ...(size === 'normal' && {
        paddingHorizontal: 6,
        height: 48,
        fontDefault: 15,
        //Icon only
        ...(!hasChildren && {
          padding: 4,
          width: 48,
        }),
      }),

      ...(size === 'small' && {
        height: 32,
        paddingHorizontal: 4,
        fontDefault: 14,
        //Icon only
        ...(!hasChildren && {
          padding: 2,
          width: 34,
        }),
      }),

      ...variantStyle,
      lineHeight: '16px',
      alignItems: 'center',
      border: 'none',
    },

    button: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      ...(isLoading && { visibility: 'hidden' }),
    },

    loader: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

export default styles;
