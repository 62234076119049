import { getAreaRegex } from '../search/area-regex';

/**
 * Function `slugify` makes string url-friendly
 * steps: Limit number of words, Replace spaces with -
 * Remove all non-word chars, Replace multiple - with single -
 * Trim - from start of text, Trim - from end of text
 */
const slugify = (text?: string | number | null, lowercase: boolean = true) => {
  if (!text || typeof text !== 'string') return '';
  //only slugify area string, Leave any extra country/county context as is
  const area = text.split('--')[0];
  const areaContext = text.split('--');
  // Slugify any area context (state/county/country) that is passed in area using "--"
  const sluggedAreaContext =
    areaContext.length > 1
      ? areaContext
          .map((context, index) =>
            index === 0
              ? '' // Skip the main area text
              : processString(context),
          )
          .join('--')
      : '';

  const sluggedArea = processString(area);

  const combined = `${sluggedArea}${sluggedAreaContext}`;
  return lowercase ? combined.toLowerCase() : combined;
};

const processString = (str: string) =>
  str
    .toString()
    // Limit number of words
    .replace(/(([^\s]+\s\s*){7})(.*)/, '$1…')
    // Replace spaces with -
    .replace(/\s+/g, '-')
    // Replace non-word chars, but not special chars like umlauts
    // @see https://dev.to/tillsanders/let-s-stop-using-a-za-z-4a0m
    .replace(getAreaRegex(true), '')
    // Replace multiple -- with single -
    .replace(/--+/g, '-')
    // Trim - from start of text
    .replace(/^-+/, '')
    // Trim - from end of text
    .replace(/-+$/, '');

export default slugify;
