import { keys } from 'ramda';
import type { Slug, UrlFriendlySlug } from '@bridebook/toolbox/src/types';

/**
 * URL slugs for specific supplier types
 */
export const supplierTypeSlugs: Partial<Record<Slug, UrlFriendlySlug>> = {
  beauty: 'beauty-hair-makeup',
  cakes: 'cakes',
  catering: 'catering',
  decoration: 'decoration-hire',
  dress: 'dress-accessories',
  entertainment: 'entertainment',
  florist: 'florists',
  jewellery: 'rings-jewellery',
  marquee: 'marquee-hire',
  menswear: 'menswear',
  music: 'musicians-bands-djs',
  photo: 'photographers',
  planners: 'planners-toastmasters-celebrants',
  stationery: 'stationery',
  transport: 'transport',
  venue: 'venues',
  video: 'videographers',
};

/**
 * Returns URL slug based on the supplier type
 */
export function mapSlugToFriendlyUrlSlug(slug?: Slug | UrlFriendlySlug) {
  const result = {
    isValidSlug: false,
    newSlug: slug as UrlFriendlySlug,
    oldSlug: null as string | null,
  };

  if (supplierTypeSlugs[slug as unknown as Slug] != null) {
    result.newSlug = supplierTypeSlugs[slug as unknown as Slug] as UrlFriendlySlug;

    if (result.isValidSlug === false) {
      result.isValidSlug = true;
    }
  } else {
    result.newSlug = slug as UrlFriendlySlug;
  }

  for (const [key, value] of Object.entries(supplierTypeSlugs)) {
    if (value === slug) {
      result.oldSlug = key;

      if (result.isValidSlug === false) {
        result.isValidSlug = true;
      }

      break;
    }
  }

  return result;
}

/**
 * Returns supplier type (Slug) based on slug from url
 */
export function mapUrlSlugToSupplierType(urlSlug: UrlFriendlySlug): Slug | undefined {
  return keys(supplierTypeSlugs).find((key) => supplierTypeSlugs[key] === urlSlug);
}
