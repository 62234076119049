// Keep this for storybook color mixer
// export const lightenDarkenColor = (color: string, amount: number) => {
//   const intensity = (255 / 100) * amount;
//
//   const rgb = color
//     .split('(')[1]
//     .split(')')[0]
//     .split(',');
//
//   const partToHex = part => {
//     const hex = part.toString(16);
//     return hex.length == 1 ? '0' + hex : hex;
//   };
//
//   const colorHex =
//     partToHex(parseInt(rgb[0])) +
//     partToHex(parseInt(rgb[1])) +
//     partToHex(parseInt(rgb[2]));
//
//   const num = parseInt(colorHex, 16);
//
//   let r = (num >> 16) + intensity;
//   let g = (num & 0x0000ff) + intensity;
//   let b = ((num >> 8) & 0x00ff) + intensity;
//
//   if (r > 255) {
//     r = 255;
//   } else if (r < 0) {
//     r = 0;
//   }
//
//   if (b > 255) {
//     b = 255;
//   } else if (b < 0) {
//     b = 0;
//   }
//
//   if (g > 255) {
//     g = 255;
//   } else if (g < 0) {
//     g = 0;
//   }
//   const transformedColor = `${r},${g},${b}`;
//
//   return rgb[3]
//     ? `rgba(${transformedColor},${rgb[3]})`
//     : `rgb(${transformedColor})`;
// };

export const alpha = (colorValue: string, amount: number): string =>
  colorValue.replace('rgb', 'rgba').replace(')', `,${amount / 100})`);

export const rgbToHex = (rgb: string) => {
  // Split into array from CSS string, eg 'rgb(118, 78, 174)'
  const rgbArray = rgb.split('(')[1].split(')')[0].split(',').map(Number);
  return (
    '#' +
    rgbArray
      .map((x) => {
        const hex = x.toString(16).toUpperCase();
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
};
