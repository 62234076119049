import { IColorTypes } from '../types';

/* ############################################################################
 *  MAIN COLORS
 * ######################################################################### */

const white = 'rgb(255, 255, 255)';
const black = 'rgb(0, 0, 0)';
const coreBlack = 'rgb(33,53,77)';

// =============== Primary colors ===============

const indigoCrush = 'rgb(77, 82, 227)'; // #4D52E3
const indigoCrush120 = 'rgb(63, 68, 211)';
const indigoCrush80 = 'rgb(113, 117, 233)';
const indigoCrush60 = 'rgb(148, 151, 238)';
const indigoCrush40 = 'rgb(184, 186, 244)';
const indigoCrush20 = 'rgb(219, 220, 249)';
const indigoCrush10 = 'rgb(237, 238, 252)';
const indigoCrush04 = 'rgb(248, 248, 254)';

// ============== Secondary colors ===============

const teal = 'rgb(176, 232, 221)'; // #B0E8DD
const teal120 = 'rgb(105, 208, 188)'; // #69D0BC
const teal80 = 'rgb(192, 237, 228)';
const teal60 = 'rgb(208, 241, 235)';
const teal40 = 'rgb(223, 246, 241)';
const teal20 = 'rgb(239, 250, 248)';

// ============== Supporting colors ===============

const blushTangerine = 'rgb(255, 166, 158)'; // #FFA69E
const blushTangerine120 = 'rgb(255, 118, 106)'; // #FF766A
const blushTangerine80 = 'rgb(255, 184, 177)';
const blushTangerine60 = 'rgb(255, 202, 197)';
const blushTangerine40 = 'rgb(255, 219, 216)';

const pinkJoy = 'rgb(255, 238, 232)'; // #FFEEE8
const pinkJoy120 = 'rgb(255, 218, 203)';
const pinkJoy60 = 'rgb(255, 245, 241)';
const pinkJoy40 = 'rgb(255, 248, 246)';

const peachRose = 'rgb(248, 213, 156)'; // #F8D59C
const peachRose120 = 'rgb(253, 189, 93)'; // #FDBD5D
const peachRose80 = 'rgb(249, 221, 176)';
const peachRose60 = 'rgb(251, 230, 196)';
const peachRose40 = 'rgb(252, 238, 215)';
const peachRose20 = 'rgb(254, 247, 235)'; // #FEF7EB

const navyGrowth = 'rgb(36, 55, 92)';

const space = 'rgb(26, 44, 64)';
const space75 = 'rgb(83, 97, 112)';
const space60 = 'rgb(118, 128, 140)';
const space40 = 'rgb(163, 171, 179)';
const space30 = 'rgb(186, 192, 198)';
const space15 = 'rgb(221, 223, 226)';
const space08 = 'rgb(237, 238, 240)';
const space04 = 'rgb(246, 247, 247)';

const errors = 'rgba(193, 1, 1, 1)'; // #C10101
const alerts = 'rgba(240, 96, 0, 1)'; // #F06000
const success = 'rgba(0, 128, 0, 1)'; // #008000

/* ############################################################################
 *  VENDORS
 * ######################################################################### */

const facebookBlue = 'rgb(59, 89, 152)'; // '#3b5998';
const facebookBlue140 = 'rgb(35, 53, 91)'; // darken(facebookBlue, 40);
const facebookBlue80 = 'rgb(98, 122, 173)'; // lighten(facebookBlue, 20)';
const twitterBlue = 'rgb(42, 169, 241)'; // '#2aa9f1';
const pinterestRed = 'rgb(196, 22, 24)'; // '#c41618';

/* ############################################################################
 *  DEPRECATED
 * ######################################################################### */

// BlueCrush Color Palette
const blueCrush = 'rgb(83, 125, 215)'; // '#537DD7';
const blueCrush140 = 'rgb(50, 75, 129)'; // darken(blueCrush, 40);
const blueCrush120 = 'rgb(66, 100, 172)'; // darken(blueCrush, 20);
const blueCrush80 = 'rgb(117, 151, 223)'; // lighten(blueCrush, 20);
const blueCrush60 = 'rgb(152, 177, 231)'; // lighten(blueCrush, 40);
const blueCrush30 = 'rgb(203, 216, 243)'; // lighten(blueCrush, 70);
const blueCrush10 = 'rgb(238, 242, 251)'; // lighten(blueCrush, 90);
const blueCrush04 = 'rgb(248, 250, 253)'; // lighten(blueCrush, 96);

// Mint Color Palette
const mint = 'rgb(45, 186, 167)'; // '#2DBAA7';
const mint140 = 'rgb(27, 112, 100)'; // darken(mint, 40);
const mint120 = 'rgb(36, 149, 134)'; // darken(mint, 20);
const mint80 = 'rgb(87, 200, 185)'; // lighten(mint, 20);
const mint60 = 'rgb(129, 214, 202)'; // lighten(mint, 40);
const mint30 = 'rgb(192, 234, 229)'; // lighten(mint, 70);
const mint10 = 'rgb(234, 248, 246)'; // lighten(mint, 90);
const mint04 = 'rgb(247, 252, 251)'; // lighten(mint, 96);

const rose = 'rgb(242, 112, 132)'; // '#F27084';
const rose140 = 'rgb(145, 67, 79)'; // darken(rose, 40);
const rose120 = 'rgb(194, 90, 106)'; // darken(rose, 20);
const rose80 = 'rgb(245, 141, 157)'; // lighten(rose, 20);
const rose60 = 'rgb(247, 169, 181)'; // lighten(rose, 40);
const rose30 = 'rgb(251, 212, 218)'; // lighten(rose, 70);
const rose10 = 'rgb(254, 241, 243)'; // lighten(rose, 90);

const yellow = 'rgb(231, 190, 62)'; // '#E7BE3E';
const yellow120 = 'rgb(185, 152, 50)'; // darken(yellow, 20);
const yellow60 = 'rgb(241, 216, 139)'; // lighten(yellow, 40);
const yellow10 = 'rgb(253, 249, 236)'; // lighten(yellow, 90);

const amber = 'rgb(221, 90, 89)'; // '#DD5A59';
const amber140 = 'rgb(133, 54, 53)'; // darken(amber, 40);
const amber120 = 'rgb(177, 72, 71)'; // darken(amber, 20);
const amber80 = 'rgb(228, 123, 122)'; // lighter(amber, 20);
const amber60 = 'rgb(235, 156, 155)'; // lighten(amber, 40);
const amber10 = 'rgb(252, 239, 238)'; // lighten(amber, 90);

const purple = 'rgb(118, 78, 174)'; // '#764EAE';
const purple120 = 'rgb(94, 62, 139)'; // darken(purple, 20);
const purple60 = 'rgb(173, 149, 206)'; // lighten(purple, 40);
const purple10 = 'rgb(241, 237, 247)'; // lighten(purple, 90);

const blogOrange = 'rgb(246, 131, 31)'; // '#f6831f';

/* ############################################################################
 *  EXPORTS
 * ######################################################################### */

const colors: IColorTypes = {
  // Primary
  indigoCrush,
  indigoCrush120,
  indigoCrush80,
  indigoCrush60,
  indigoCrush40,
  indigoCrush20,
  indigoCrush10,
  indigoCrush04,
  teal,
  teal120,
  teal80,
  teal60,
  teal40,
  teal20,
  // Secondary
  blushTangerine,
  blushTangerine120,
  blushTangerine80,
  blushTangerine60,
  blushTangerine40,
  pinkJoy,
  pinkJoy120,
  pinkJoy60,
  pinkJoy40,
  peachRose,
  peachRose120,
  peachRose80,
  peachRose60,
  peachRose40,
  peachRose20,
  white,
  black,
  coreBlack,
  navyGrowth,
  space,
  space75,
  space60,
  space40,
  space30,
  space15,
  space08,
  space04,
  // Others
  transparent: 'transparent',
  errors,
  alerts,
  success,
  // Vendors
  facebookBlue,
  facebookBlue140,
  facebookBlue80,
  twitterBlue,
  pinterestRed,
  /**
   * DEPRECATED - replace with colors from the palette above
   */
  blueCrush,
  blueCrush140,
  blueCrush120,
  blueCrush80,
  blueCrush60,
  blueCrush30,
  blueCrush10,
  blueCrush04,
  mint,
  mint140,
  mint120,
  mint80,
  mint60,
  mint30,
  mint10,
  mint04,
  rose,
  rose140,
  rose120,
  rose80,
  rose60,
  rose30,
  rose10,
  yellow,
  yellow120,
  yellow60,
  yellow10,
  amber,
  amber140,
  amber120,
  amber80,
  amber60,
  amber10,
  purple,
  purple120,
  purple60,
  purple10,
  blogOrange,
  mineShaft: 'rgb(74, 74, 74)',
  silver: 'rgb(155, 155, 155)',
  alto: 'rgb(191, 191, 191)',
  dust: 'rgb(218, 216, 216)',
  grey: 'rgb(226, 226, 226)',
  soft: 'rgb(240, 240, 240)',
  light: 'rgb(247, 247, 247)',
  paleGrey: 'rgb(247, 248, 250)',
  forest: 'rgb(14, 166, 117)',
  forest10: 'rgb(231, 246, 241)',
};

export default colors;
