import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import gazetteer, { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';

/**
 * Get the current market for logged in/out users based
 * on the wedding location or if not set, use the current
 * router parameter `locale`.
 *
 * There is a similar hook for logged out users (see
 * app-basic/lib/i18n/hooks/use-market.ts).
 *
 * @returns {Market} The current market.
 *
 */
export const useMarket = (): Market => {
  const {
    query: { market: marketFromQuery },
  } = useRouter();

  /**
   * We don't know which kind of store we get, since this
   * can be called from logged out as well as from logged in.
   **/
  const l10nUserLocale = useSelector((state: Record<any, any>) => state?.users?.user?.l10n?.locale);
  const l10nUserCurrency = useSelector(
    (state: Record<any, any>) => state?.users?.user?.l10n?.currency,
  );
  const l10nWeddingCountry = useSelector(
    (state: Record<any, any>) => state?.weddings?.profile?.l10n?.country,
  );
  const l10nWeddingCurrency = useSelector(
    (state: Record<any, any>) => state?.weddings?.profile?.l10n?.currency,
  );

  /**
   * Infer the market first from the wedding country or
   * if not set, use the current router locale with a fallback
   * to the default locale.
   **/
  const getMarket = useCallback(
    () =>
      l10nWeddingCountry
        ? gazetteer.getMarketByCountry(l10nWeddingCountry, CountryCodes.GB)
        : gazetteer.getMarketByURL(
            Array.isArray(marketFromQuery) ? marketFromQuery[0] : marketFromQuery ?? undefined,
            CountryCodes.GB,
          ),
    [marketFromQuery, l10nWeddingCountry],
  );

  const [market, setMarket] = useState<Market>(getMarket());

  useEffect(() => {
    const localeMarket = getMarket();

    /**
     * Use the users locale if one was set.
     */
    if (l10nUserLocale) {
      localeMarket.locale = l10nUserLocale;
    }

    /**
     * Use the wedding currency or user currency if one was set.
     */
    if (l10nWeddingCurrency) {
      localeMarket.currency = l10nWeddingCurrency;
    }

    /**
     * This shouldn't be enabled until we have a way for the user to override it's preferred currency.
     * Also, without forex support, this is not really useful.
     */
    // eslint-disable-next-line no-constant-condition
    if (false && l10nUserCurrency) {
      localeMarket.currency = l10nUserCurrency;
    }

    setMarket(localeMarket);
  }, [getMarket, l10nUserCurrency, l10nUserLocale, l10nWeddingCurrency, marketFromQuery]);

  return market;
};
