import React from 'react';
import colors from '../../../themes/variables/colors';
import { TColor } from '../../../types';
import componentStyles from './loading-circle.style';

interface IProps {
  size?: number;
  color?: TColor;
  animate?: boolean;
  center?: boolean;
}

const LoadingCircle = ({ size = 30, color = 'white', animate = true, center = false }: IProps) => {
  const styles = componentStyles();

  return (
    <svg
      version="1.1"
      id="loader-1"
      xlinkHref="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 40 40"
      enableBackground="new 0 0 40 40"
      style={center ? styles.center : { position: 'relative' }}>
      <path
        fill={colors[color]}
        d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
        C22.32,8.481,24.301,9.057,26.013,10.047z">
        {animate && (
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 20 20"
            to="360 20 20"
            dur="0.9s"
            repeatCount="indefinite"
          />
        )}
      </path>
    </svg>
  );
};
export default LoadingCircle;
