import { FelaCSS } from '../components/fela/flowtypes';

type TPropValue = string | number;
type TStylePropValue = TPropValue[] | TPropValue;

// generates appropriate media querries to passed css prop, if no array is passed then a singular value is returned
const responsiveStylePropQueries = (
  propName: keyof FelaCSS,
  propValue: TStylePropValue,
): FelaCSS => {
  const isValueArray = Array.isArray(propValue);
  const getStyledValue = (value: TPropValue) => ({ [propName]: value });
  const xsValue = getStyledValue(isValueArray ? propValue[0] : propValue);

  if (!isValueArray) {
    return xsValue;
  }

  const smValue = propValue[1] ? getStyledValue(propValue[1]) : xsValue;
  const mdValue = propValue[2] ? getStyledValue(propValue[2]) : smValue;
  const lgValue = propValue[3] ? getStyledValue(propValue[3]) : mdValue;

  return {
    maxWidthXsMax: {
      ...xsValue,
    },
    maxWidthSmMax: {
      minWidthSm: {
        ...smValue,
      },
    },
    maxWidthMdMax: {
      minWidthMd: {
        ...mdValue,
      },
    },
    minWidthLg: {
      ...lgValue,
    },
  };
};

export default responsiveStylePropQueries;
