import { pipe } from 'ramda';
import type { Market } from '@bridebook/toolbox/src/gazetteer';
import { env } from 'lib/env';
import { removeForwardSlash, removeTrailingSlash } from 'lib/utils/strings';

/**
 * Returns a full current url with base domain, market prefix and pathname.
 * Accepts a pathname with or without leading slash.
 */
export const getLocalisedCanonical = (market: Market, pathname?: string) => {
  const routePostfix = (() => {
    const basicPrefix = 'basic/';
    // If pathname is empty or just a slash, return the base domain with no market prefix (global home)
    if (!pathname || pathname === '/') return '';

    const route = pipe(
      removeTrailingSlash,
      removeForwardSlash,
      // Replace existing market prefix with the target market prefix
      // It matches either a bare market prefix like 'uk' or a deeper route 'uk/foo'
      (str: string) => str.replace(/^[a-z]{2}(?:-[a-z]{2}){0,2}([/]|$)/, `${market.prefix}$1`),
      // Remove basic prefix
      (str: string) => (str.startsWith(basicPrefix) ? str.replace(basicPrefix, '') : str),
    )(pathname || '');

    // Check if the route starts with a market prefix, followed by a potential forward slash.
    const hasMarketPrefix = new RegExp(`^${market.prefix}(?:[/]|$)`).test(route);

    // Add the market prefix to the route if it's not already there.
    return hasMarketPrefix ? `/${route}` : `/${market.prefix}/${route}`;
  })();

  return env.COUPLESIDE_URL + routePostfix;
};
